<template>
<div >
  <v-carousel 
  :show-arrows="false"
  cycle
  hide-delimiters
  height="450"
  interval="6000"
  >
    <v-carousel-item
      class = 'img-carousel'
      v-for="(image,i) in images"
      :key="i"
      :src="image.src"
      transition="fade-transition"
      reverse-transition="fade-transition"
      position='center'
      cover
    >
      <v-row class="fill-height" align="center" justify="center">    
          <div class='logo-carousel'>
            <v-img class='logoHome'  
              src="../assets/logoKhoury_2.png"
              max-height="200"
              max-width="100%"
              contain
              position='center center'
              >              
            </v-img>            
          </div>      
          <div class='homeTitle txt-carousel'>
            <div class='title text-center text-md-h2 white--text text-sm-h2 text-h4'>Traiteur La Goutte d'Or</div> 
            <h3 class='text-center homeSecondTitle white--text'>by Edgar Khoury</h3>
          </div>
      </v-row>      
    </v-carousel-item>
  </v-carousel>

  <div class="block home">
    <v-container>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap" rel="stylesheet">  
      <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">
      <meta name="google-site-verification" content="jM76oQuV0pxLKdnGWP5nnxM50u6_QzLP3J3rz7ID7d4" />
          <v-row
          class='flex-column-reverse flex-md-row'
          justify='center'
            align = 'center'>
            <v-col
            :cols = '12'
            :sm = '6'
            justify='center'
            align = 'center'>
                  <v-avatar
                  size='188'
                  tile
                  >
                    <v-img
                  class='imgHome'
                  src="../assets/photos/edgar_khoury_1.jpg"
                  height="100%"
                  >
                    <template v-slot:placeholder>
                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular
                            indeterminate
                            color="amber"
                        ></v-progress-circular>
                        </v-row>
                    </template>
                    </v-img>                
                  </v-avatar>
            </v-col>
          
            <v-col  
            :cols = '12'
            :sm = '6'
            align = 'center'        
            >
              <div class='txt-home'>      
                <!-- <h4>Edgar Khoury</h4> -->
                <p>                
                {{ $t('home.firstp')}} 
                </p>
                <br>
                <p>
                {{ $t('home.secondp')}}
                </p>
                <br>
                <p>
                {{ $t('home.thirdp')}}
                </p>
              </div>
            </v-col>
          </v-row>
    </v-container>
  </div>
</div>  
</template>

<script>

export default {
  name: 'Home',
  components: {
    // HelloWorld
    // LocaleSwitcher,
    // LanguageSwitcher
  },
  data: () => ({
    alignments: [
        'start',
        'center',
        'end',
      ],
    images: [
              {
                id:0,
                src: require('../assets/photos/no_compressed/chateau_vecquee_foule.jpg')
              },
              {
                id:1,
                src: require('../assets/photos/no_compressed/chateau_vecquee_dessert.jpg')
              },
              {
                id:2,
                src: require('../assets/photos/BuffetCafe.jpg')
              }
      ]
  }),
}
</script>

<style scoped>
  .home {
      font-family: 'Raleway', sans-serif;
  }

  .v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}

  .txt-carousel {
    /* background: rgba(0, 0, 0, 0.5); */
    /* filter:blur(8px); */
    width: 100%;

  }

  .logo-carousel{
    background: rgba(0, 0, 0, 0);
    width: 100%;
    /* height: 100%;    */
  }

  .img-carousel {
    opacity: 1;
  }

  .logoHome {
    max-width: 60%;
    height: auto;
    margin-top: 1em;
  }

  .block {
  padding: 60px 0;
  border-bottom: 1px solid darken(white, 10%);
}

  .title{
    font-family: 'Dosis', sans-serif!important;
  }

  .homeTitle {
    font-size: 30px;
    padding: 10px 0;
    /* margin: 0 0 40px; */
    position: relative;
    backdrop-filter: blur(3.5px);
  }

  .homeSecondTitle{
    font-family: 'Caveat', cursive;
    font-weight:normal;
    margin-left: 30%;
    font-style: italic;
  }

  .txt-home{
    text-align: justify;
  }
</style>
